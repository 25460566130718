import { SvgIcon } from "@mui/material";
import { FC } from "react";

const DefaultDocIcon: FC<{}> = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="50px"
    height="50px"
    viewBox="-30 -22 140 140"
  >
    <path
      stroke="#5A5B59"
      stroke-width="0.1"
      fillRule="evenodd"
      d="M8,4.00277853 C8,1.79210499 9.79405245,0 12.0098932,0 L56,0 L80,24 L80,92.0005854 C80,94.2094011 78.2022516,96 76.0005854,96 L11.9994146,96 C9.79059889,96 8,94.2101231 8,91.9972215 L8,4.00277853 Z M16,8 L16,88 L72,88 L72,32 L48,32 L48,8 L16,8 Z M24,24 L40,24 L40,32 L24,32 L24,24 Z M24,40 L64,40 L64,48 L24,48 L24,40 Z M24,56 L64,56 L64,64 L24,64 L24,56 Z M24,72 L56,72 L56,80 L24,80 L24,72 Z"
    />
  </SvgIcon>
);
export default DefaultDocIcon;
