import { SvgIcon } from "@mui/material";
import { FC } from "react";

const PrintIcon: FC = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="800px"
    height="800px"
    viewBox="-3 -3 26 26"
    style={{ color: "#5A5B59" }}
  >
    <g id="layer1">
      <path
        d="M 3,1 V 5 H 0 v 9 h 3 v 5 h 14 v -5 h 3 V 5 H 17 V 1 Z M 4,2 H 16 V 5 H 4 Z M 1,6 h 2 14 2 v 7 H 17 V 10 H 3 v 3 H 1 Z m 15,1 v 1 h 2 V 7 Z M 4,11 h 12 v 2 1 4 H 4 v -4 -1 z m 1,1 v 1 h 10 v -1 z m 0,2 v 1 h 10 v -1 z m 0,2 v 1 h 10 v -1 z"
        style={{
          fill: "#5A5B59",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0,
        }}
      />
    </g>
  </SvgIcon>
);
export default PrintIcon;
