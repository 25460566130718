import {
  PayloadAction,
  createAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import axios from "axios";
import { RootState } from "..";
import {
  IDocBuilderModelDto,
  IDocModelDto,
  IPrecedentDto,
} from "../../../shared/dto/document.dto";
import {
  IAgfsListsDto,
  ICCCDSuccessDto,
  ICivilFFModelDto,
  ICivilListsDto,
  ICivilTotalsDto,
  ICrimeListsDto,
  ICrownListsDto,
  IInvoiceDto,
  IInvoiceInitDto,
  ILgfsListsDto,
} from "../../../shared/dto/invoice/invoice.dto";
import { environment } from "../../../shared/environments";
import { defaultHeaders } from "../../../shared/utils/request-utils";
import { close } from "../../../shared/utils/sdk-utils";
import {
  setErrors,
  setLoader,
  setValidationErrors,
  setWarningAcknowledgment,
} from "../app/appSlice";

export interface InvoiceState {
  agfsLists: IAgfsListsDto | undefined;
  civilLists: ICivilListsDto | undefined;
  civilTotals: ICivilTotalsDto | undefined;
  crimeLists: ICrimeListsDto | undefined;
  crownLists: ICrownListsDto | undefined;
  docModel: IDocModelDto;
  init: IInvoiceInitDto;
  invoice: IInvoiceDto | undefined;
  isPrintingEnabled: boolean | undefined;
  lgfsLists: ILgfsListsDto | undefined;
  saving: boolean;
  loading: boolean;
  precedentId: string;
  precedentList: IPrecedentDto[] | undefined;
  uploadSuccess: ICCCDSuccessDto | undefined;
  errors: string[] | undefined;
  errorAction: (() => void) | undefined;
}

const initialState: InvoiceState = {
  init: {
    billingStageId: 1,
    invoiceId: "",
    isReversedOrReversal: false,
    matterTypeId: 0,
    matterTypeName: "",
  },
  agfsLists: undefined,
  civilLists: undefined,
  civilTotals: undefined,
  crimeLists: undefined,
  crownLists: undefined,
  docModel: { documentId: "" },
  invoice: undefined,
  isPrintingEnabled: false,
  lgfsLists: undefined,
  saving: false,
  loading: false,
  precedentId: "",
  precedentList: undefined,
  uploadSuccess: undefined,
  errors: undefined,
  errorAction: undefined,
};

export const clearSemaphore = createAsyncThunk(
  "clearSemaphore",
  async (semaphoreId: string, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/invoice/clearsemaphore/${semaphoreId}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const createDocument = createAsyncThunk(
  "createDocument",
  async (docBuilderModel: IDocBuilderModelDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.post(
        `${environment().backendUrl}/document/GenerateDocument`,
        docBuilderModel,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const createInvoice = createAsyncThunk(
  "createInvoice",
  async (invoice: IInvoiceDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.post(
        `${environment().backendUrl}/invoice`,
        invoice,
        await defaultHeaders(sdkApi)
      );
      if (!!res.data.length && !!res.data[0].code) {
        thunkApi.rejectWithValue(res.data);
      } else {
        return res.data;
      }
    } catch (errorResponse: any) {
      thunkApi.dispatch(setLoader(false));
      if (errorResponse.response.data.code) {
        thunkApi.dispatch(
          setWarningAcknowledgment(errorResponse.response.data, () => {
            thunkApi
              .dispatch(
                createInvoice({
                  ...invoice,
                  warningAcknowledgments: [
                    ...(invoice?.warningAcknowledgments || []),
                    errorResponse.response.data?.code || "",
                  ],
                })
              )
              .then(() =>
                thunkApi
                  .dispatch(clearSemaphore(invoice.semaphoreId || ""))
                  .then(() => close(sdkApi))
              );
          })
        );
      } else {
        thunkApi.dispatch(
          setErrors([errorResponse.response.data.error.errorDesc])
        );
      }
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  "deleteInvoice",
  async (invoice: IInvoiceDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.put(
        `${environment().backendUrl}/invoice/${invoice.invoiceId}/delete`,
        invoice,
        await defaultHeaders(sdkApi)
      );
      if (!!res.data.length && !!res.data[0].code) {
        thunkApi.rejectWithValue(res.data);
      } else {
        return res.data;
      }
    } catch (errorResponse: any) {
      thunkApi.dispatch(setLoader(false));
      if (errorResponse.response.data.code) {
        thunkApi.dispatch(
          setWarningAcknowledgment(errorResponse.response.data, () => {
            thunkApi
              .dispatch(
                deleteInvoice({
                  ...invoice,
                  warningAcknowledgments: [
                    ...(invoice?.warningAcknowledgments || []),
                    errorResponse.response.data?.code || "",
                  ],
                })
              )
              .then(() =>
                thunkApi
                  .dispatch(clearSemaphore(invoice.semaphoreId || ""))
                  .then(() => close(sdkApi))
              );
          })
        );
      } else {
        thunkApi.dispatch(
          setErrors([errorResponse.response.data.error.errorDesc])
        );
      }
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getAgfsFixedFee = createAsyncThunk(
  "getAgfsFixedFee",
  async (
    data: {
      matterId: string | undefined;
      taxFree: boolean | undefined;
      advocateType: string | undefined;
      trialType: string | undefined;
      offenceId: string | undefined;
      actualDays: number | undefined;
      withinOneMonth: boolean | undefined;
      afterOneMonth: boolean | undefined;
      noOfDefendants: number | undefined;
      noOfAdditionalCase: number | undefined;
      discontinued: string | undefined;
      additionalFee: number | undefined;
      additionalFeeVat: number | undefined;
      schemeNo: number | undefined;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/invoice/${
          data.matterId
        }/agfstotals?taxFree=${data.taxFree}&advocateType=${
          data.advocateType
        }&trialType=${data.trialType}&offenceId=${data.offenceId}&actualDays=${
          data.actualDays
        }&withinOneMonth=${data.withinOneMonth}&afterOneMonth=${
          data.afterOneMonth
        }&noOfDefendants=${data.noOfDefendants}&noOfAdditionalCase=${
          data.noOfAdditionalCase
        }&discontinued=${data.discontinued}&additionalFee=${
          data.additionalFee
        }&additionalFeeVat=${data.additionalFeeVat}&schemeNo=${data.schemeNo}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getAgfsInvoiceLists = createAsyncThunk(
  "getAgfsInvoiceLists",
  async (
    data: {
      schemeNo: number | undefined;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/invoice/agfsgroups?schemeNo=${
          data.schemeNo
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getCivilInvoiceLists = createAsyncThunk(
  "getCivilInvoiceLists",
  async (
    data: {
      matterTypeId: number | undefined;
      billingStageId: number | undefined;
      tableNo: number | undefined;
      rate: string | undefined;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/invoice/civilgroups?matterTypeId=${
          data.matterTypeId
        }&billingStageId=${data.billingStageId}&tableNo=${data.tableNo}&rate=${
          data.rate
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getCrimeInvoiceLists = createAsyncThunk(
  "getCrimeInvoiceLists",
  async (
    data: {
      matterId: string | undefined;
      matterTypeId: number | undefined;
      tableNo: number | undefined;
      rate: string | undefined;
      undesignatedArea: boolean | undefined;
      billingStageId: number | undefined;
      tableNoRepOrder: number | undefined;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/invoice/crimegroups?matterId=${
          data.matterId
        }&matterTypeId=${data.matterTypeId}&tableNo=${data.tableNo}&rate=${
          data.rate
        }&undesignatedArea=${data.undesignatedArea}&billingStageId=${
          data.billingStageId
        }&tableNoRepOrder=${data.tableNoRepOrder}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getCrownInvoiceLists = createAsyncThunk(
  "getCrownInvoiceLists",
  async (
    data: {
      matterTypeId: number | undefined;
      billingStageId: number | undefined;
      tableNo: number | undefined;
      rate: string | undefined;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/invoice/crowngroups?matterTypeId=${
          data.matterTypeId
        }&billingStageId=${data.billingStageId}&tableNo=${data.tableNo}&rate=${
          data.rate
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getInvoice = createAsyncThunk(
  "getInvoice",
  async (invoiceId: string, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/invoice/${invoiceId}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setValidationErrors(
          [(errorResponse as any).response.data.error.errorDesc as string],
          () => close(sdkApi)
        )
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getItemsForInvoice = createAsyncThunk(
  "getItemsForInvoice",
  async (
    data: {
      matterId: string | undefined;
      billingStageId: number | undefined;
      disbursementsOnly: boolean | undefined;
      poa?: boolean | undefined;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;

    try {
      const poaParam = data.poa ? `&poa=${data.poa}` : "";
      const res = await axios.get(
        `${environment().backendUrl}/invoice/${
          data.billingStageId
        }/newinvoice?matterId=${data.matterId}&billingStageId=${
          data.billingStageId
        }&disbursementsOnly=${data.disbursementsOnly}${poaParam}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setValidationErrors(
          [(errorResponse as any).response.data.error.errorDesc as string],
          () => close(sdkApi)
        )
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getLgfsInvoiceLists = createAsyncThunk(
  "getLgfsInvoiceLists",
  async (
    data: {
      matterId: string | undefined;
      billingStageId: number | undefined;
      tableNo: number | undefined;
      rate: string | undefined;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/invoice/lf1groups?matterId=${
          data.matterId
        }&tableNo=${data.tableNo}&rate=${data.rate}&billingStageId=${
          data.billingStageId
        }`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getLgfsFixedFee = createAsyncThunk(
  "getLgfsFixedFee",
  async (
    data: {
      matterId: string | undefined;
      taxFree: boolean | undefined;
      typeOfCase: string | undefined;
      trialType: string | undefined;
      offenceClass: string | undefined;
      trialDays: number | undefined;
      ppe: number | undefined;
      noOfDefendants: number | undefined;
      noOfCommittal: number | undefined;
      additionalFee: number | undefined;
      additionalFeeVat: number | undefined;
      tableNo: number | undefined;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/invoice/${
          data.matterId
        }/lf1totals?taxFree=${data.taxFree}&typeOfCase=${
          data.typeOfCase
        }&trialType=${data.trialType}&offenceClass=${
          data.offenceClass
        }&trialLength=${data.trialDays}&ppe=${data.ppe}&noOfDefendants=${
          data.noOfDefendants
        }&committalForTrial=${data.noOfCommittal}&additionalFee=${
          data.additionalFee
        }&additionalFeeVat=${data.additionalFeeVat}&tableNo=${data.tableNo}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const getPrecedentList = createAsyncThunk(
  "getPrecedentList",
  async (
    data: {
      matterTypeId: number | undefined;
      billingStageId: number | undefined;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.get(
        `${environment().backendUrl}/document?matterTypeId=${
          data.matterTypeId
        }&billingStageId=${data.billingStageId}`,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const postCivilFixedFee = createAsyncThunk(
  "postCivilFixedFee",
  async (civilFFModel: ICivilFFModelDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.post(
        `${environment().backendUrl}/invoice/${
          civilFFModel.matterId
        }/civiltotals`,
        civilFFModel,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const reverseInvoice = createAsyncThunk(
  "reverseInvoice",
  async (invoice: IInvoiceDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.post(
        `${environment().backendUrl}/invoice/reversal`,
        invoice,
        await defaultHeaders(sdkApi)
      );
      if (!!res.data.length && !!res.data[0].code) {
        thunkApi.rejectWithValue(res.data);
      } else {
        return res.data;
      }
    } catch (errorResponse: any) {
      thunkApi.dispatch(setLoader(false));
      if (errorResponse.response.data.code) {
        thunkApi.dispatch(
          setWarningAcknowledgment(errorResponse.response.data, () => {
            thunkApi
              .dispatch(
                reverseInvoice({
                  ...invoice,
                  warningAcknowledgments: [
                    ...(invoice?.warningAcknowledgments || []),
                    errorResponse.response.data?.code || "",
                  ],
                })
              )
              .then(() =>
                thunkApi
                  .dispatch(clearSemaphore(invoice.semaphoreId || ""))
                  .then(() => close(sdkApi))
              );
          })
        );
      } else {
        thunkApi.dispatch(
          setErrors([errorResponse.response.data.error.errorDesc])
        );
      }
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const updateInvoice = createAsyncThunk(
  "updateInvoice",
  async (invoice: IInvoiceDto, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.put(
        `${environment().backendUrl}/invoice/${invoice.invoiceId}`,
        invoice,
        await defaultHeaders(sdkApi)
      );
      if (!!res.data.length && !!res.data[0].code) {
        thunkApi.rejectWithValue(res.data);
      } else {
        return res.data;
      }
    } catch (errorResponse: any) {
      thunkApi.dispatch(setLoader(false));
      if (errorResponse.response.data.code) {
        thunkApi.dispatch(
          setWarningAcknowledgment(errorResponse.response.data, () => {
            thunkApi
              .dispatch(
                updateInvoice({
                  ...invoice,
                  warningAcknowledgments: [
                    ...(invoice?.warningAcknowledgments || []),
                    errorResponse.response.data?.code || "",
                  ],
                })
              )
              .then(() => {
                thunkApi
                  .dispatch(clearSemaphore(invoice.semaphoreId || ""))
                  .then(() => close(sdkApi));
              });
          })
        );
      } else {
        thunkApi.dispatch(
          setErrors([errorResponse.response.data.error.errorDesc])
        );
      }
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const uploadInvoiceToCCCD = createAsyncThunk(
  "uploadInvoiceToCCCD",
  async (data: { invoice: IInvoiceDto; invoiceUrl: string }, thunkApi) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      const res = await axios.post(
        `${environment().backendUrl}/invoice/${data.invoiceUrl}?matterId=${
          data.invoice.matterId
        }&invoiceId=${data.invoice.invoiceId}`,
        data.invoice,
        await defaultHeaders(sdkApi)
      );
      return res.data;
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const downloadCRM7XML = createAsyncThunk(
  "downloadCRM7XML",
  async (
    data: {
      matterGuid: string;
      matterReference: string;
      invoiceId: string;
      invoiceNumber: number;
      uploadToMatter: boolean;
    },
    thunkApi
  ) => {
    const sdkApi = (thunkApi.getState() as RootState).app.sdkApi;
    try {
      await axios
        .post(
          `${environment().backendUrl}/document/crm7xml?matterId=${
            data.matterGuid
          }&invoiceId=${data.invoiceId}&uploadToMatter=${data.uploadToMatter}`,
          {},
          {
            ...(await defaultHeaders(sdkApi)),
            responseType: "blob",
          }
        )
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute(
            "download",
            `CRM7 - ${data.matterReference || ""} Invoice ${
              data.invoiceNumber || ""
            }.xml`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((reason) => {
          return thunkApi.rejectWithValue(reason.response);
        });
    } catch (errorResponse: any) {
      thunkApi.dispatch(
        setErrors([errorResponse.response.data.error.errorDesc])
      );
      return thunkApi.rejectWithValue({
        errorResponse: errorResponse.response.data,
      });
    }
  }
);

export const setInvoiceErrors = createAction(
  "setInvoiceErrors",
  (errors: string[] | undefined, errorAction?: () => void) => {
    return {
      payload: { errors: errors, errorAction: errorAction },
    };
  }
);

export const setInvoice = createAction(
  "setInvoice",
  (invoice: IInvoiceDto | undefined) => {
    return {
      payload: invoice,
    };
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setInit: (state, action) => {
      state.init = action.payload;
    },
    setPrecedentId: (state, action) => {
      state.precedentId = action.payload;
    },
    setPrintingEnabled: (state, action) => {
      state.isPrintingEnabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearSemaphore.pending, (state) => {
        state.loading = true;
      })
      .addCase(clearSemaphore.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        state.docModel = action.payload;
        state.loading = false;
      })
      .addCase(createInvoice.pending, (state) => {
        state.loading = true;
        state.saving = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload;
        state.loading = false;
        state.saving = false;
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.loading = false;
        state.saving = false;
      })
      .addCase(deleteInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload;
        state.loading = false;
      })
      .addCase(deleteInvoice.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAgfsFixedFee.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgfsFixedFee.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getAgfsInvoiceLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgfsInvoiceLists.fulfilled, (state, action) => {
        state.agfsLists = action.payload;
        state.loading = false;
      })
      .addCase(getCivilInvoiceLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCivilInvoiceLists.fulfilled, (state, action) => {
        state.civilLists = action.payload;
        state.loading = false;
      })
      .addCase(getCrownInvoiceLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCrownInvoiceLists.fulfilled, (state, action) => {
        state.crownLists = action.payload;
        state.loading = false;
      })
      .addCase(getCrimeInvoiceLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCrimeInvoiceLists.fulfilled, (state, action) => {
        state.crimeLists = action.payload;
        state.loading = false;
      })
      .addCase(getInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload;
        state.loading = false;
      })
      .addCase(getInvoice.rejected, (state) => {
        state.loading = false;
      })
      .addCase(setInvoice, (state, action) => {
        state.invoice = action.payload;
      })
      .addCase(getItemsForInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItemsForInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload;
        state.loading = false;
      })
      .addCase(getItemsForInvoice.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getLgfsFixedFee.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLgfsFixedFee.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getLgfsInvoiceLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLgfsInvoiceLists.fulfilled, (state, action) => {
        state.lgfsLists = action.payload;
        state.loading = false;
      })
      .addCase(getPrecedentList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrecedentList.fulfilled, (state, action) => {
        state.precedentList = ((action.payload || []) as IPrecedentDto[]).sort(
          (a, b) =>
            `${a.code} - ${a.description}` > `${b.code} - ${b.description}`
              ? 1
              : -1
        );
        state.loading = false;
      })
      .addCase(reverseInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(reverseInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload;
        state.loading = false;
      })
      .addCase(reverseInvoice.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postCivilFixedFee.pending, (state) => {
        state.loading = true;
      })
      .addCase(postCivilFixedFee.fulfilled, (state, action) => {
        state.civilTotals = action.payload;
        state.loading = false;
      })
      .addCase(updateInvoice.pending, (state) => {
        state.loading = true;
        state.saving = true;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload;
        state.loading = false;
        state.saving = false;
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.loading = false;
        state.saving = false;
      })
      .addCase(uploadInvoiceToCCCD.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadInvoiceToCCCD.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadSuccess = action.payload;
      })
      .addCase(uploadInvoiceToCCCD.rejected, (state, action) => {
        state.loading = false;
        state.uploadSuccess = undefined;
      })
      .addCase(setInvoiceErrors, (state, action) => {
        state.errors = action.payload.errors;
        state.errorAction = action.payload.errorAction;
      });
  },
});

export const { loading, setInit, setPrecedentId, setPrintingEnabled } =
  invoiceSlice.actions;

export default invoiceSlice.reducer;
