import { SvgIcon } from "@mui/material";
import { FC } from "react";

const BinIcon: FC = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-120 -91 750 750"
    style={{ color: "#5A5B59" }}
  >
    <g>
      <g>
        <path d="M316.325,44.522V0H195.68l-0.003,44.522H61.217v87.982h21.616c3.975,75.409,20.548,350.983,21.319,363.803L105.097,512    h301.806l0.944-15.694c0.771-12.821,17.345-288.394,21.319-363.803h21.616V44.522H316.325z M229.069,33.391h53.866v11.13h-53.866    V33.391z M375.458,478.609H136.542c-3.633-60.548-16.681-278.597-20.27-346.105h279.456    C392.14,200.012,379.091,418.06,375.458,478.609z M417.391,99.112H94.609V77.913h322.783V99.112z" />
      </g>
    </g>
    <g>
      <g>
        <rect x="239.304" y="167.947" width="33.391" height="280.031" />
      </g>
    </g>
    <g>
      <g>
        <rect
          x="160.292"
          y="168.19"
          transform="matrix(0.9986 -0.0521 0.0521 0.9986 -15.8157 9.64)"
          width="33.39"
          height="279.952"
        />
      </g>
    </g>
    <g>
      <g>
        <rect
          x="195.052"
          y="291.462"
          transform="matrix(0.0521 -0.9986 0.9986 0.0521 9.8344 626.6741)"
          width="279.952"
          height="33.39"
        />
      </g>
    </g>
  </SvgIcon>
);
export default BinIcon;
