import { ReactNode } from "react";
import DocIcon from "../images/doc-icon";
import ExcelIcon from "../images/excel-icon";

export const getDocumentIcon = (docType: string) => {
  let icon: ReactNode = <DocIcon height={14} />;
  switch (docType?.toLowerCase()) {
    case "docx":
    case "doc":
      icon = <DocIcon height={14} />;
      break;
    case "xls":
    case "xlsx":
    case "xlsm":
      icon = <ExcelIcon height={14} />;
      break;
    default:
      icon = <DocIcon height={14} />;
      break;
  }
  return icon;
};
