import { IconButton, Button, Typography, Tooltip } from "@mui/material";
import { FC, useState, ReactNode } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { deleteFee, deleteFees } from "../../app/store/fees/feeSlice";
import { IFeeDto } from "../../shared/dto/fee.dto";
import { close } from "../../shared/utils/sdk-utils";
import CustomDialog from "./custom-dialog";
import BinIcon from "../../shared/images/bin-icon";

interface IFeeDeleteProps {
  fee?: IFeeDto;
  feeRef?: IFeeDto;
  multiFee?: IFeeDto[];
  multiFeeRef?: IFeeDto[];
  multi?: boolean;
  feeId?: string;
}

const FeeDeleteButton: FC<IFeeDeleteProps> = ({
  fee,
  feeRef,
  multiFee,
  multiFeeRef,
  multi,
  feeId,
}) => {
  const dispatch = useAppDispatch();

  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);

  const [dialogText, setDialogText] = useState<string | undefined>(undefined);
  const [dialogTitle, setDialogTitle] = useState<string | undefined>(undefined);
  const [dialogActions, setDialogActions] = useState<ReactNode | undefined>(
    undefined
  );
  const closeDialog = () => {
    setDialogTitle(undefined);
    setDialogText(undefined);
    setDialogActions(undefined);
  };

  return (
    <>
      <Tooltip placement="bottom-end" title="Delete">
        <IconButton
          disableRipple
          disableTouchRipple
          color="primary"
          onClick={() => {
            if (fee?.deleted) {
              setDialogText("Item(s) have already been deleted.");
              setDialogTitle("Alert");
              setDialogActions(
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeDialog();
                  }}
                  color="secondary"
                  disableRipple
                >
                  OK
                </Button>
              );
            } else if (fee?.billed) {
              setDialogText("Item(s) have already been billed.");
              setDialogTitle("Alert");
              setDialogActions(
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeDialog();
                  }}
                  color="secondary"
                  disableRipple
                >
                  OK
                </Button>
              );
            } else {
              if (
                JSON.stringify(feeRef) !== JSON.stringify(fee) ||
                JSON.stringify(multiFeeRef) !== JSON.stringify(multiFee) ||
                fee?.itemId ||
                feeId
              ) {
                setDialogText("Delete now?");
                setDialogTitle("Alert");
                setDialogActions(
                  <>
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (!!multi && !!feeId) {
                          dispatch(deleteFees(feeId))
                            .unwrap()
                            .then(() => {
                              close(sdkApi);
                              closeDialog();
                            })
                            .catch((e) => console.error(e));
                        } else if (!!fee?.itemId) {
                          dispatch(deleteFee(fee.itemId))
                            .unwrap()
                            .then(() => {
                              close(sdkApi);
                              closeDialog();
                            })
                            .catch((e) => console.error(e));
                        } else {
                          close(sdkApi);
                          closeDialog();
                        }
                      }}
                      disableRipple
                    >
                      Yes
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        closeDialog();
                      }}
                      color="secondary"
                      disableRipple
                    >
                      No
                    </Button>
                  </>
                );
              } else {
                close(sdkApi);
              }
            }
          }}
        >
          <BinIcon />
        </IconButton>
      </Tooltip>
      <CustomDialog
        isOpen={!!dialogTitle}
        title={dialogTitle || ""}
        onClose={() => closeDialog()}
        showWarningIcon
        actions={dialogActions}
        content={
          <Typography style={{ minWidth: 295 }}>{dialogText}</Typography>
        }
      />
    </>
  );
};

export default FeeDeleteButton;
