import { IPrecedentDto } from "../dto/document.dto";
import { IInvoiceDto } from "../dto/invoice/invoice.dto";

export const createInvoiceDocument = (
  isReversal: boolean,
  notificationAction: (message: string) => void,
  createDocumentAction: (id: string) => void,
  createAfterSaveAction: (id: string) => void,
  invoice?: IInvoiceDto,
  precedentId?: string
) => {
  if (precedentId && invoice) {
    if (!!invoice.invoiceId) {
      if (invoice.deleted) {
        notificationAction(
          "Documents cannot be created for a deleted invoice."
        );
      } else if (isReversal) {
        notificationAction(
          "Documents cannot be created for a reversed or a reversal."
        );
      } else {
        createDocumentAction(precedentId);
      }
    } else {
      notificationAction(
        "Document will be created after the invoice has been saved."
      );
      createAfterSaveAction(precedentId);
    }
  }
};

export const checkDownloadCRM7XML = (
  isReversal: boolean,
  notificationAction: (message: string) => void,
  downloadXMLAction: () => void,
  invoice?: IInvoiceDto
) => {
  if (invoice) {
    if (!!invoice.invoiceId) {
      if (invoice.deleted) {
        notificationAction(
          "XML document cannot be created for a deleted invoice."
        );
      } else if (isReversal) {
        notificationAction(
          "XML document cannot be created for a reversed or a reversal."
        );
      } else {
        downloadXMLAction();
      }
    } else {
      notificationAction("Please save the invoice first.");
    }
  }
};

export const printReport = (
  notificationAction: (message: string) => void,
  printAction: () => void,
  printAfterSaveAction: () => void,
  invoice?: IInvoiceDto
) => {
  if (invoice) {
    if (!!invoice.invoiceId) {
      printAction();
    } else {
      notificationAction("This transaction will be printed after saving.");
      printAfterSaveAction();
    }
  }
};

export const calculateBalanceDue = (add: number[], subtract: number[]) => {
  const balanceDue = subtract.reduce(
    (sum, current) => sum - current,
    add.reduce((sum, current) => sum + current, 0)
  );
  return balanceDue < 0 ? 0 : balanceDue;
};

export const getTextWidth = (text?: string) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.font = "13px Segoe UI";
    return ctx.measureText(text || "").width;
  }
};

export const getPrecedentCodesMaxLength = (list: IPrecedentDto[]) => {
  const mappedCodes = list.map((precedent) => precedent.code);
  return getTextWidth(getMaxLengthString(mappedCodes));
};

export const getMaxLengthString = (arr: string[]) => {
  if (!arr || arr.length === 0) {
    return undefined;
  }

  let maxLengthString = arr[0];
  let maxLength = arr[0].length;

  for (let i = 1; i < arr.length; i++) {
    if (arr[i].length > maxLength) {
      maxLength = arr[i].length;
      maxLengthString = arr[i];
    }
  }

  return maxLengthString;
};
