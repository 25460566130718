import { SvgIcon } from "@mui/material";
import { FC } from "react";

const DownloadIcon: FC = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
    viewBox="-7 -5 36 36"
    style={{ color: "#5A5B59" }}
  >
    <path d="M24,24H0v-8h2v6h20v-6h2V24z M12,18.4l-7.7-7.7l1.4-1.4l5.3,5.3V0h2v14.6l5.3-5.3l1.4,1.4L12,18.4z" />
  </SvgIcon>
);
export default DownloadIcon;
