import { Box, Button, Chip, Menu, MenuItem, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import CustomDialog from "./custom-dialog";
import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import { environment } from "../../shared/environments";
import StaffAvatar from "./staff-avatar";
import { staffComparator } from "../../shared/utils/grid-utils";
import NumberDisplay from "./number-display";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IPOAItemModelDto } from "../../shared/dto/fee.dto";
import LocalDatePicker from "./date-picker";

interface IDialogProps {
  isOpen: boolean;
  disabled: boolean;
  items: IPOAItemModelDto[] | undefined;
  onSelect: (items: IPOAItemModelDto[]) => void;
  onClose: () => void;
}

const PoaDialog: FC<IDialogProps> = ({
  isOpen,
  disabled,
  items,
  onSelect,
  onClose,
}) => {
  const users = useAppSelector((state: RootState) => state.app.users);
  const initialised = useRef(false);
  const selectedFromDate = useRef<string | null>(null);
  const selectedToDate = useRef<string | null>(null);
  const selectedType = useRef<string>("<All>");
  const initialisedItems = useRef(false);

  const [sortModel, setSortModel] = useState<GridSortModel | undefined>(
    undefined
  );
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [originalSelectedModel, setOriginalSelectedModel] =
    useState<GridRowSelectionModel>([]);
  const [total, setTotal] = useState(0);
  const [currentItems, setCurrentItems] = useState<
    IPOAItemModelDto[] | undefined
  >(undefined);
  const [showWarning, setShowWarning] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!initialisedItems.current) {
      setOriginalSelectedModel(rowSelectionModel);
      initialisedItems.current = true;
    }
  }, [rowSelectionModel]);

  useEffect(() => {
    if (items && items?.length && !initialised.current) {
      let tempItems = [...(items || [])];
      if (selectedFromDate.current) {
        tempItems = tempItems.filter((i) =>
          moment(i.transactionDate, "YYYY-MM-DDTHH:mm:ss").isSameOrAfter(
            moment(selectedFromDate.current, environment().dateFormat).startOf(
              "day"
            )
          )
        );
      }
      if (selectedToDate.current) {
        tempItems = tempItems.filter((i) =>
          moment(i.transactionDate, "YYYY-MM-DDTHH:mm:ss").isSameOrBefore(
            moment(selectedToDate.current, environment().dateFormat).startOf(
              "day"
            )
          )
        );
      }
      setCurrentItems(tempItems);
      initialised.current = true;
    }
  }, [items]);

  const filterItems = () => {
    let tempItems = [...(items || [])];
    if (selectedFromDate.current) {
      tempItems = tempItems.filter((i) =>
        moment(i.transactionDate, "YYYY-MM-DDTHH:mm:ss").isSameOrAfter(
          moment(selectedFromDate.current, environment().dateFormat).startOf(
            "day"
          )
        )
      );
    }
    if (selectedToDate.current) {
      tempItems = tempItems.filter((i) =>
        moment(i.transactionDate, "YYYY-MM-DDTHH:mm:ss").isSameOrBefore(
          moment(selectedToDate.current, environment().dateFormat).startOf(
            "day"
          )
        )
      );
    }
    if (selectedType.current === "Fees") {
      tempItems = tempItems.filter((i) => i.type === 1 && !i.timed);
    }
    if (selectedType.current === "Time") {
      tempItems = tempItems.filter((i) => i.type === 1 && i.timed);
    }
    if (selectedType.current === "Disbursements") {
      tempItems = tempItems.filter((i) => i.type !== 1);
    }
    setCurrentItems(tempItems);
  };

  const columns: GridColDef[] = [
    {
      disableColumnMenu: true,
      field: "transactionDate",
      headerName: "DATE",
      width: 80,
      renderCell: (params) => {
        return moment(params.value, "YYYY-MM-DDTHH:mm:ss").format(
          environment().dateFormat
        );
      },
    },
    {
      disableColumnMenu: true,
      field: "type",
      headerName: "TYPE",
      headerAlign: "center",
      align: "center",
      width: 100,
      valueGetter: (params) => {
        return params.value === 1 ? (params.row.timed ? "TIME" : "FEE") : "";
      },
      renderCell: (params) => {
        return (
          params.value !== "" && (
            <Chip
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                fontSize: 11,
                width: 180,
                height: 24,
                backgroundColor:
                  params.value === "TIME" ? "#C2D0DA" : "#CCE4EF",
                color: params.value === "TIME" ? "#004876" : "#32ABE2",
                borderRadius: 1,
              }}
              label={params.value}
            />
          )
        );
      },
    },
    {
      disableColumnMenu: true,
      field: "billingDescription",
      headerName: "DESCRIPTION",
      flex: 1,
    },
    {
      field: "feeEarnerId",
      headerName: "STAFF",
      width: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const staff = users?.find((u) => u.staffId === params.value);
        return <StaffAvatar staff={staff} />;
      },
      sortComparator: (v1, v2, c1, c2) =>
        staffComparator(v1, v2, c1, c2, users || []),
    },
    {
      disableColumnMenu: true,
      field: "feeHoursQuantity",
      headerName: "HRS/QTY",
      headerAlign: "right",
      align: "right",
      width: 75,
      renderCell: (params) => {
        return (
          <NumberDisplay
            value={params.value}
            displayAsQty={!params.row.timed}
          />
        );
      },
    },
    {
      disableColumnMenu: true,
      field: "totalExTax",
      headerName: "AMOUNT",
      headerAlign: "right",
      width: 100,
      align: "right",
      renderCell: (params) => {
        return <NumberDisplay value={params.value} showPrefix />;
      },
    },
  ];

  return (
    <CustomDialog
      isOpen={!!isOpen}
      closeIcon
      noPadding
      hideOverflow
      title={!disabled ? "Select Items" : "View Items"}
      onClose={onClose}
      maxWidth="md"
      minWidth={900}
      actions={
        <>
          {!disabled && (
            <Button
              variant="contained"
              onClick={() => {
                if (!!rowSelectionModel.length) {
                  const currentItems = (items || []).filter(
                    (item) =>
                      item.itemId && rowSelectionModel.includes(item.itemId)
                  );
                  onSelect(currentItems);
                  setOriginalSelectedModel(rowSelectionModel);
                  onClose();
                } else {
                  setShowWarning(true);
                }
              }}
              disableRipple
            >
              OK
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              setRowSelectionModel(originalSelectedModel);
              const currentItems = (items || []).filter(
                (item) =>
                  item.itemId && originalSelectedModel.includes(item.itemId)
              );
              setTotal(
                currentItems.reduce((total, item) => {
                  return total + item.totalExTax;
                }, 0)
              );
              onClose();
            }}
            color="secondary"
            disableRipple
          >
            Cancel
          </Button>
        </>
      }
      content={
        <>
          <div className="displayFlex" style={{ padding: "10px 15px" }}>
            <div className="displayFlex" style={{ margin: "auto" }}>
              <div style={{ width: 115 }}>
                <LocalDatePicker
                  value={selectedFromDate.current}
                  onUpdate={(value) => {
                    selectedFromDate.current = value;
                    filterItems();
                  }}
                />
              </div>
              <div style={{ margin: "auto" }}>
                <Typography style={{ paddingLeft: 8, paddingRight: 5 }}>
                  To
                </Typography>
              </div>
              <div style={{ width: 115, paddingLeft: 10 }}>
                <LocalDatePicker
                  value={selectedToDate.current}
                  onUpdate={(value) => {
                    selectedToDate.current = value;
                    filterItems();
                  }}
                />
              </div>
            </div>
            <div className="flex1" />
            <Button
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
              variant="contained"
              disableRipple
              sx={{
                width: 150,
                borderRight: "1px solid grey",
                backgroundColor: "#405476",
                ":hover": {
                  backgroundColor: "#62738F",
                },
              }}
            >
              <div className="displayFlex" style={{ width: 195 }}>
                <FilterAltOutlinedIcon fontSize="small" />
                <div className="flex1" />
                {selectedType.current}
                <div className="flex1" />
                <ArrowDropDownIcon fontSize="small" />
              </div>
            </Button>
            <Menu
              className="contextMenu"
              open={!!anchorEl}
              anchorReference="anchorEl"
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              disableScrollLock
              PaperProps={{ style: { padding: "2px 0px" } }}
            >
              <MenuItem
                onClick={() => {
                  selectedType.current = "<All>";
                  setAnchorEl(null);
                  filterItems();
                }}
              >
                <Typography className="search-menu-item" width={92}>
                  {"<All>"}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  selectedType.current = "Time";
                  setAnchorEl(null);
                  filterItems();
                }}
              >
                <Typography className="search-menu-item" width={92}>
                  Time
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  selectedType.current = "Fees";
                  setAnchorEl(null);
                  filterItems();
                }}
              >
                <Typography className="search-menu-item" width={92}>
                  Fees
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  selectedType.current = "Disbursements";
                  setAnchorEl(null);
                  filterItems();
                }}
              >
                <Typography className="search-menu-item" width={90}>
                  Disbursements
                </Typography>
              </MenuItem>
            </Menu>
          </div>
          <Box
            sx={{
              width: "100%",
              height: "calc(100vh - 233px)",
              maxWidth: 900,
              backgroundColor: "#fff",
            }}
          >
            <DataGridPro
              checkboxSelection={!disabled}
              columns={columns.map((c) => {
                return {
                  ...c,
                  disableColumnMenu: true,
                  hideSortIcons: true,
                  renderHeader: (params: GridColumnHeaderParams) => {
                    return (
                      <div
                        style={{
                          display: "grid",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            height: 1,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          {sortModel?.find(
                            (f: any) =>
                              f.field === params.colDef.field &&
                              f.sort === "asc"
                          ) && (
                            <KeyboardArrowUpRoundedIcon className="tableHeaderIcon" />
                          )}
                          {sortModel?.find(
                            (f: any) =>
                              f.field === params.colDef.field &&
                              f.sort === "desc"
                          ) && (
                            <ExpandMoreRoundedIcon className="tableHeaderIcon" />
                          )}
                        </div>
                        <div style={{ display: "block" }}>
                          <Typography
                            style={{ fontWeight: 500 }}
                            textAlign={params.colDef.headerAlign}
                          >
                            {params.colDef.headerName}
                          </Typography>
                        </div>
                      </div>
                    );
                  },
                };
              })}
              columnHeaderHeight={35}
              columnVisibilityModel={{
                invoiceId: false,
              }}
              keepNonExistentRowsSelected
              disableRowSelectionOnClick
              getRowId={(row: any) => row.itemId}
              hideFooter
              initialState={{
                sorting: {
                  sortModel: [{ field: "transactionDate", sort: "asc" }],
                },
              }}
              localeText={{
                noRowsLabel:
                  "No items can be found based on your search criteria.",
              }}
              onRowSelectionModelChange={(
                newRowSelectionModel: GridRowSelectionModel
              ) => {
                const currentItems = (items || []).filter(
                  (item) =>
                    item.itemId && newRowSelectionModel.includes(item.itemId)
                );
                setTotal(
                  currentItems.reduce((total, item) => {
                    return total + item.totalExTax;
                  }, 0)
                );
                setRowSelectionModel(newRowSelectionModel);
              }}
              // onRowClick={(params) => {
              //   let currentList = rowSelectionModel;
              //   if (currentList.includes(params.row.itemId)) {
              //     currentList = currentList.filter(
              //       (i) => i !== params.row.itemId
              //     );
              //   } else {
              //     currentList = [...currentList, params.row.itemId];
              //   }
              //   const currentItems = (items || []).filter(
              //     (item) => item.itemId && currentList.includes(item.itemId)
              //   );
              //   setTotal(
              //     currentItems.reduce((total, item) => {
              //       return total + item.totalExTax;
              //     }, 0)
              //   );
              //   setRowSelectionModel(currentList);
              // }}
              onSortModelChange={(model: any) => setSortModel(model)}
              rowSelectionModel={rowSelectionModel}
              rowHeight={35}
              rows={currentItems || []}
              scrollbarSize={15}
              slots={{
                columnSortedAscendingIcon: KeyboardArrowUpRoundedIcon,
                columnSortedDescendingIcon: ExpandMoreRoundedIcon,
              }}
              sx={{
                backgroundColor: "#fff",
                borderBottom: 0,
                borderRadius: 0,
                ".MuiDataGrid-overlay": { color: "#0060bb" },
                ".MuiDataGrid-virtualScroller": { overflowX: "hidden" },
              }}
            />
          </Box>
          <div
            className="invoice-total-footer"
            style={{
              marginLeft: 0,
              height: 28,
              flexWrap: "wrap",
              alignContent: "center",
            }}
          >
            <div className="flex4">
              <Typography
                align="left"
                style={{ color: "#db761d", fontWeight: 600 }}
              >
                SELECTED: {disabled ? items?.length : rowSelectionModel.length}
              </Typography>
            </div>
            <div className="flex1">
              <Typography style={{ color: "#000", fontWeight: 600 }}>
                TOTAL
              </Typography>
            </div>
            <div
              style={
                (currentItems || [])?.length > 12
                  ? { width: 100, paddingRight: 15 }
                  : { width: 100 }
              }
            >
              <NumberDisplay
                value={
                  disabled
                    ? (items || []).reduce((total, item) => {
                        return total + item.totalExTax;
                      }, 0)
                    : total
                }
                showPrefix
                align="right"
                bold
              />
            </div>
          </div>
          <CustomDialog
            isOpen={!!showWarning}
            title="Alert"
            onClose={() => setShowWarning(false)}
            showWarningIcon
            actions={
              <Button
                variant="outlined"
                onClick={() => {
                  setShowWarning(false);
                }}
                color="secondary"
                disableRipple
              >
                OK
              </Button>
            }
            content={
              <>
                <div className="displayFlex">
                  <Typography>Please select at least one item.</Typography>
                </div>
              </>
            }
          />
        </>
      }
    />
  );
};

export default PoaDialog;
