import { SvgIcon } from "@mui/material";
import { FC } from "react";

interface IIconProps {
  height?: number;
}
const DocIcon: FC<IIconProps> = ({ height }) => {
  return (
    <SvgIcon
      style={{ height: height ? height : 18 }}
      viewBox="0 0 48 48"
      id="doctype-doc-24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M10 39.267L2 38.11V13.89l8-1.156V2h26l10 10v34H10v-6.733z"
        ></path>
        <path
          fill="#CCC"
          d="M26 14h6v2h-6v-2zm-8-6h14v2H18V8zm8 12h16v2H26v-2zm0 18h16v2H26v-2zm0-12h16v2H26v-2zm0 6h16v2H26v-2z"
        ></path>
        <path
          fill="#9DA7B3"
          fillRule="nonzero"
          d="M10 39.267l2 .289V44h32V12.828L35.172 4H12v8.444l-2 .29V2h26l10 10v34H10v-6.733zM34 14h10v2H32V4h2v10z"
        ></path>
        <path
          fill="#28579B"
          d="M22 11v30L2 38.111V13.89L22 11zm-3 9h-2.405l-1.717 8.917L13.046 20h-2.634l-2.175 8.807-1.603-8.697H4L6.748 32h2.748l2.061-8.477L13.504 32h2.633L19 20z"
        ></path>
      </g>
    </SvgIcon>
  );
};

export default DocIcon;
