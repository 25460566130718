import { SvgIcon } from "@mui/material";
import { FC } from "react";

const UploadIcon: FC = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
    viewBox="-7 -5 36 36"
    style={{ color: "#5A5B59" }}
  >
    <path d="M24,24H0v-8h2v6h20v-6h2V24z M13,18h-2V3.8L5.7,9.1L4.3,7.7L12,0l7.7,7.7l-1.4,1.4L13,3.8V18z" />
  </SvgIcon>
);
export default UploadIcon;
