import { SvgIcon } from "@mui/material";
import { FC } from "react";

interface IIconProps {
  disabled?: boolean;
}

const ReverseIcon: FC<IIconProps> = ({ disabled }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 110 110"
      style={
        !disabled ? { color: "#5A5B59", strokeWidth: 1 } : { color: "#d5d6d5" }
      }
    >
      <path d="M40,35 L60,35 C74.1962733,35 85,43.9380755 85,57.5 C85,71.0619245 74.1962733,80 60,80 L50,80 C47.2385763,80 45,77.7614237 45,75 C45,72.2385763 47.2385763,70 50,70 L60,70 C69.0479048,70 75,65.0757477 75,57.5 C75,49.9242523 69.0479048,45 60,45 L40,45 L40,60 L15,40 L40,20 L40,35 Z" />
    </SvgIcon>
  );
};

export default ReverseIcon;
