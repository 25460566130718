import { SvgIcon } from "@mui/material";
import { FC } from "react";

interface IIconProps {
  height?: number;
}
const ExcelIcon: FC<IIconProps> = ({ height }) => {
  return (
    <SvgIcon
      style={{ height: height ? height : 18 }}
      viewBox="0 0 48 48"
      id="doctype-excel"
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M10 2h28l8 8v36H10z"></path>
        <path
          fill="#9DA7B3"
          fillRule="nonzero"
          d="M34 3.5H11.5v41h33V14H34V3.5zm1.5 0v9h9v-1.879L37.379 3.5H35.5zM10 2h28l8 8v36H10V2z"
        ></path>
        <path
          fill="#86BFA0"
          d="M26 34h6v6h-6v-6zm8 0h8v6h-8v-6zm-8-8h6v6h-6v-6zm8 0h8v6h-8v-6zm-8-8h6v6h-6v-6zm8 0h8v6h-8v-6z"
        ></path>
        <path fill="#CCC" d="M26 8h6v8h-6z"></path>
        <path fill="#FFF" d="M3 19h17v14H3z"></path>
        <path
          fill="#1C7245"
          d="M22 11v30L2 38.111V13.89L22 11zm-5.99 9.11h-2.53l-1.98 4.254h-.11l-1.65-4.146H6.88l2.97 5.891L6.66 32H9.3l2.09-4.364L13.37 32h2.75l-3.19-6 3.08-5.89z"
        ></path>
      </g>
    </SvgIcon>
  );
};

export default ExcelIcon;
