import {
  Button,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  getUsers,
  setLoader,
  setValidationErrors,
} from "../../app/store/app/appSlice";
import { getTaskCodes } from "../../app/store/fees/feeSlice";
import {
  clearSemaphore,
  createDocument,
  createInvoice,
  getInvoice,
  getItemsForInvoice,
  reverseInvoice,
  setInit,
  setPrintingEnabled,
  updateInvoice,
} from "../../app/store/invoice/invoiceSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import {
  IDocBuilderModelDto,
  IDocModelDto,
} from "../../shared/dto/document.dto";
import { IPOAItemModelDto } from "../../shared/dto/fee.dto";
import { IInvoiceDto } from "../../shared/dto/invoice/invoice.dto";
import { useAppInit } from "../../shared/hooks/use-app-init";
import {
  calculateBalanceDue,
  printReport,
} from "../../shared/utils/invoice-utils";
import {
  close,
  getDecodedToken,
  onBeforeClose,
  openDocument,
  openReport,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  formatValue,
  getFormattedUFN,
  newGuid,
} from "../../shared/utils/utils";
import { validate } from "../../shared/utils/validation-utils";
import CustomDialog from "../components/custom-dialog";
import LocalDatePicker from "../components/date-picker";
import Footer from "../components/footer";
import InvoiceDeleteButton from "../components/invoice-delete-button";
import InvoiceErrorDialog from "../components/invoice-error-dialog";
import NumberDisplay from "../components/number-display";
import PoaDialog from "../components/poa-dialog";
import ReversalInfo from "../components/reversal-info";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { listStatusItems } from "./lists";
import ReverseIcon from "../../shared/images/reverse";
import PrintIcon from "../../shared/images/print-icon";

const InvoicePOADetails: FC = () => {
  const dispatch = useAppDispatch();

  const invoice = useAppSelector((state) => state.invoice);
  const taskCodes = useAppSelector((state) => state.fee.taskCodes);

  const isInvoiceDetailsInitialised = useRef(false);
  const isInvoiceInitialised = useRef(false);
  const isMatterDetailsInitialised = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const invoiceRef = useRef<IInvoiceDto | undefined>(undefined);
  const decodedToken = useRef<any>(undefined);

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const saving = useSelector((state: RootState) => state.invoice.saving);
  const userList = useSelector((state: RootState) => state.app.users);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState<
    IInvoiceDto | undefined
  >(undefined);
  const [notificationMessage, setNotificationMessage] = useState<
    string | undefined
  >(undefined);
  const [showUnsavedData, setShowUnsavedData] = useState(false);
  const [toBeReversed, setToBeReversed] = useState(false);
  const [showFeesDialog, setShowFeesDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState<
    IPOAItemModelDto[] | undefined
  >(undefined);

  const [searchParams] = useSearchParams();

  const infoIcon = require("../../shared/images/info.png");

  const feeEarnerValidator = {
    error: "",
    isDisabled: false,
    isRequired: true,
  };

  const isFormDisabled =
    invoice.init.isReversedOrReversal ||
    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
    toBeReversed ||
    !!invoice.init.invoiceId;

  const columns: GridColDef[] = [
    {
      disableColumnMenu: true,
      field: "id",
      headerName: "ID",
      width: 0,
    },
    {
      disableColumnMenu: true,
      field: "description",
      flex: 1,
      headerName: "DESCRIPTION",
      sortable: false,
      renderCell: (params: any) => {
        if (params.value === "Fixed Fee") {
          return (
            <>
              {params.value}
              <Tooltip
                style={{ paddingLeft: 5 }}
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    Fixed Fee:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON
                          ?.isClaimExceptional &&
                          currentInvoiceDetails.externalJSON.fixedFeeValue !== 0
                          ? 0
                          : currentInvoiceDetails?.externalJSON?.fixedFeeValue,
                        invoice.init.isReversedOrReversal,
                        true,
                        true
                      )}
                    <br />
                    Fee Limit:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON
                          ?.isClaimExceptional &&
                          currentInvoiceDetails.externalJSON.fixedFeeValue !== 0
                          ? 0
                          : currentInvoiceDetails?.externalJSON?.fixedFeeLimit,
                        invoice.init.isReversedOrReversal,
                        true,
                        true
                      )}
                  </div>
                }
              >
                <img alt="warning" src={infoIcon} />
              </Tooltip>
              {currentInvoiceDetails?.externalJSON?.isClaimExceptional &&
                currentInvoiceDetails.externalJSON.fixedFeeValue !== 0 && (
                  <Typography color="grey" marginLeft={6}>
                    (This claim is Exceptional)
                  </Typography>
                )}
            </>
          );
        }

        return params.value;
      },
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "time",
      headerAlign: "center",
      headerName: "TIME",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          notCurrency
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "amount",
      headerAlign: "center",
      headerName: "AMOUNT",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "tax",
      headerAlign: "center",
      headerName: "TAX",
      sortable: false,
      type: "number",
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
  ];

  const handleClose = useCallback(() => {
    if (invoiceRef.current && invoiceRef.current.semaphoreId) {
      dispatch(clearSemaphore(invoiceRef.current.semaphoreId)).then(() => {
        close(sdkApi);
      });
    } else {
      close(sdkApi);
    }
  }, [dispatch, sdkApi]);

  const getData = useCallback(() => {
    if (!isInvoiceInitialised.current) {
      isInvoiceInitialised.current = true;
      getDecodedToken(sdkApi).then((payload) => {
        decodedToken.current = payload;
      });
      dispatch(
        setInit({
          billingStageId: searchParams.get("billingstageid"),
          invoiceId: searchParams.get("invoiceid"),
          isReversedOrReversal: searchParams.get("reversal")
            ? searchParams.get("reversal")
            : false,
          matterTypeId: appInit?.laMatterTypeId,
          matterTypeName: appInit?.laMatterTypeName,
        })
      );

      dispatch(getTaskCodes());
      dispatch(getMatterDetails(false)).then(() => {
        dispatch(getUsers());
      });
    }
  }, [dispatch, appInit, searchParams, sdkApi]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !isMatterDetailsInitialised.current) {
      isMatterDetailsInitialised.current = true;

      if (invoice.init.invoiceId) {
        dispatch(getInvoice(invoice.init.invoiceId)).then((action) => {
          if (
            !!invoice.init.invoiceId &&
            !!action.payload.externalJSON?.poa.items
          ) {
            setSelectedItems(action.payload.externalJSON?.poa.items);
          }
          setWindowTitle(
            `${matterDetails?.matterReference || ""} ${
              matterDetails?.matterDescription || ""
            }`,
            sdkApi,
            60,
            action.payload.externalJSON?.windowTitle
              ? `- ${action.payload.externalJSON?.windowTitle}`
              : ""
          );
        });
      } else if (invoice.init.billingStageId) {
        dispatch(
          getItemsForInvoice({
            matterId: matterDetails?.matterId,
            billingStageId: invoice?.init.billingStageId,
            disbursementsOnly: false,
            poa: true,
          })
        );
        setWindowTitle(
          `${matterDetails?.matterReference || ""} ${
            matterDetails?.matterDescription || ""
          }`,
          sdkApi,
          60,
          "- New Payment on Account"
        );
      }
    }
  }, [
    dispatch,
    matterDetails,
    invoice.init,
    isMatterDetailsInitialised,
    sdkApi,
  ]);

  useEffect(() => {
    if (!!invoice.invoice && !isInvoiceDetailsInitialised.current) {
      isInvoiceDetailsInitialised.current = true;

      setCurrentInvoiceDetails(invoice.invoice);
      invoiceRef.current = invoice.invoice;
    }
  }, [
    currentInvoiceDetails,
    invoice.invoice,
    isInvoiceDetailsInitialised,
    dispatch,
  ]);

  useEffect(() => {
    invoiceRef.current = currentInvoiceDetails;
  }, [currentInvoiceDetails]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi && !!invoice.invoice) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => {
        if (
          JSON.stringify(invoice.invoice) !== JSON.stringify(invoiceRef.current)
        ) {
          setShowUnsavedData(true);
        } else {
          handleClose();
        }
      }, sdkApi);
    }
  }, [sdkApi, invoice.invoice, handleClose]);

  const getTotals = () => {
    let calculationLabel: string =
      "Profit Costs + Advocacy (FAS only) + Expert Fees + Disbursements";
    let totalTime = (selectedItems || [])
      ?.filter((f) => f.timed)
      .reduce((total, item) => {
        return total + item.feeHoursQuantity;
      }, 0);
    let totalCost: number = (selectedItems || []).reduce((total, item) => {
      return total + item.totalExTax;
    }, 0);
    let totalVat: number = (selectedItems || []).reduce((total, item) => {
      return total + item.totalTax;
    }, 0);

    return {
      totalCost,
      totalTime,
      totalVat,
      calculationLabel,
    };
  };

  const getRequiredFields = (data: IInvoiceDto) => {
    let fields = [];

    fields.push({
      value: data?.externalJSON?.dateConcluded,
      label: "Date Concluded",
    });

    fields.push({
      value: data?.invoiceDate,
      label: "Date Billed",
    });

    if (feeEarnerValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.ffFeeEarnerId,
        label: "Fee Earner",
      });

    return fields;
  };

  const getDataRows = () => {
    const expertTask = taskCodes?.find((t) => t.nameFileAs === "EF");
    const expertItems = (selectedItems || [])?.filter(
      (f) => f.taskCodeId === expertTask?.taskCodeId
    );

    const fasTasks = taskCodes?.filter((t) =>
      t.billingDescription.includes("FAS")
    );
    const fasItems = (selectedItems || [])?.filter(
      (f) => !!(fasTasks || [])?.find((t) => t.taskCodeId === f.taskCodeId)
    );

    const otherItems = (selectedItems || [])?.filter(
      (f) =>
        !!(
          !(fasTasks || [])?.find((t) => t.taskCodeId === f.taskCodeId) &&
          f.taskCodeId !== expertTask?.taskCodeId
        )
    );
    const disbursmentItems = otherItems.filter((i) => i.type === 2);
    const profitCostItems = otherItems.filter((i) => i.type !== 2);

    const tempProfitCostTotalExVat = profitCostItems.reduce((total, item) => {
      return total + item.totalExTax;
    }, 0);
    const tempProfitCostTotalVat = profitCostItems.reduce((total, item) => {
      return total + item.totalTax;
    }, 0);
    const tempFASTotalExVat = fasItems.reduce((total, item) => {
      return total + item.totalExTax;
    }, 0);
    const tempFASTotalVat = fasItems.reduce((total, item) => {
      return total + item.totalTax;
    }, 0);
    const tempExpertTotalExVat = expertItems.reduce((total, item) => {
      return total + item.totalExTax;
    }, 0);
    const tempExpertTotalVat = expertItems.reduce((total, item) => {
      return total + item.totalTax;
    }, 0);
    const tempDisbTotalExVat = disbursmentItems.reduce((total, item) => {
      return total + item.totalExTax;
    }, 0);
    const tempDisbTotalVat = disbursmentItems.reduce((total, item) => {
      return total + item.totalTax;
    }, 0);
    // console.warn("expertTask", expertTask);
    // console.warn("expertItems", expertItems);
    // console.warn("fasTasks", fasTasks);
    // console.warn("fasItems", fasItems);
    // console.warn("otherItems", otherItems);
    // console.warn("disbursmentItems", disbursmentItems);
    // console.warn("profitCostItems", profitCostItems);
    // console.warn("selectedItems", selectedItems);

    // const time = (selectedItems || [])
    //   ?.filter((f) => f.timed)
    //   .reduce((total, item) => {
    //     return total + item.feeHoursQuantity;
    //   }, 0);
    // console.warn("time", time);
    // const totalExTax = (selectedItems || []).reduce((total, item) => {
    //   return total + item.totalExTax;
    // }, 0);
    // console.warn("totalExTax", totalExTax);
    // const totalTax = (selectedItems || []).reduce((total, item) => {
    //   return total + item.totalTax;
    // }, 0);
    // console.warn("totalTax", totalTax);

    const dataRows = [
      {
        id: newGuid(),
        description: "Profit Costs",
        time: !!profitCostItems?.find((f) => f.timed)
          ? profitCostItems
              ?.filter((f) => f.timed)
              .reduce((total, item) => {
                return total + item.feeHoursQuantity;
              }, 0)
          : undefined,
        amount: tempProfitCostTotalExVat,
        tax: tempProfitCostTotalVat,
      },
      {
        id: newGuid(),
        description: "Advocacy (FAS only)",
        time: fasItems.find((f) => f.timed)
          ? fasItems
              ?.filter((f) => f.timed)
              .reduce((total, item) => {
                return total + item.feeHoursQuantity;
              }, 0)
          : undefined,
        amount: tempFASTotalExVat,
        tax: tempFASTotalVat,
      },
      {
        id: newGuid(),
        description: "Expert Fees",
        time: expertItems.find((f) => f.timed)
          ? expertItems
              ?.filter((f) => f.timed)
              .reduce((total, item) => {
                return total + item.feeHoursQuantity;
              }, 0)
          : undefined,
        amount: tempExpertTotalExVat,
        tax: tempExpertTotalVat,
      },
      {
        id: newGuid(),
        description: "Disbursements",
        time: !!disbursmentItems?.find((f) => f.timed)
          ? disbursmentItems
              ?.filter((f) => f.timed)
              .reduce((total, item) => {
                return total + item.feeHoursQuantity;
              }, 0)
          : undefined,
        amount: tempDisbTotalExVat,
        tax: tempDisbTotalVat,
      },
    ];
    return dataRows;
  };

  const renderCriteria = () => {
    return (
      <>
        <div className="displayFlex">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex3" style={{ display: "inline-flex" }}>
                <TextField
                  disabled
                  value={matterDetails?.matterReference || ""}
                  variant="outlined"
                  style={{ width: 110 }}
                />
                <Typography
                  style={{ marginTop: 3, marginLeft: 5 }}
                  maxWidth={450}
                  noWrap
                >
                  {matterDetails?.matterDescription}
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex1" />
          <div style={{ width: 260, paddingLeft: 25 }}>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel>Invoice No.</InputLabel>
              </div>
              <div className="flex1">
                <TextField
                  disabled
                  placeholder="Auto no"
                  variant="outlined"
                  value={currentInvoiceDetails?.invoiceNumber || ""}
                  style={{ width: 108 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="displayFlex">
          <div style={{ width: 425, display: "flex", flexDirection: "column" }}>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel>UFN</InputLabel>
              </div>
              <div className="flex3" style={{ display: "inline-flex" }}>
                <TextField
                  disabled
                  fullWidth
                  value={
                    matterDetails?.legalAidObj?.ufnNumber
                      ? getFormattedUFN(
                          matterDetails?.legalAidObj?.ufnNumber,
                          matterDetails?.legalAidObj?.ufnDate
                        )
                      : ""
                  }
                  variant="outlined"
                  style={{
                    maxWidth: 110,
                    minWidth: 110,
                  }}
                />
              </div>
            </div>
            <div style={{ height: 60 }} />
            <div>
              <Button
                color="secondary"
                disableRipple
                onClick={() => {
                  setShowFeesDialog(true);
                }}
                style={{ height: 26 }}
                variant="outlined"
              >
                {!currentInvoiceDetails?.invoiceId
                  ? "Select Items"
                  : "View Items"}
              </Button>
            </div>
          </div>
          <div className="flex1" />
          <div style={{ width: 250 }} />
          <div className="flex1" />
          <div style={{ width: 260, paddingLeft: 25 }}>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required>Date Concluded</InputLabel>
              </div>
              <div className="flex1" style={{ marginRight: 4 }}>
                <LocalDatePicker
                  disabled={isFormDisabled}
                  onUpdate={(value: any) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.dateConcluded
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            dateConcluded: newValue,
                          },
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.dateConcluded
                      ? currentInvoiceDetails.externalJSON?.dateConcluded
                      : null
                  }
                />
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required>Date Billed</InputLabel>
              </div>
              <div className="flex1" style={{ marginRight: 4 }}>
                <LocalDatePicker
                  disabled={isFormDisabled && !toBeReversed}
                  onUpdate={(value: any) => {
                    if (value !== currentInvoiceDetails?.invoiceDate) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          invoiceDate: newValue,
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.invoiceDate
                      ? currentInvoiceDetails.invoiceDate
                      : null
                  }
                />
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel>Status</InputLabel>
              </div>
              <div className="flex4">
                <Select
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      status: e.target.value,
                    });
                  }}
                  sx={{
                    maxWidth: 170,
                    minWidth: 170,
                  }}
                  disabled={
                    isFormDisabled && currentInvoiceDetails?.status === 3
                  }
                  value={
                    !!currentInvoiceDetails?.status ||
                    currentInvoiceDetails?.status === 0
                      ? currentInvoiceDetails?.status
                      : ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {listStatusItems.map((item: any, index: number) => (
                    <option key={`invoiceStatus-${index}`} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required={feeEarnerValidator.isRequired}>
                  Staff
                  <Tooltip
                    style={{ paddingLeft: 5 }}
                    title="Defines who will be allocated to the fixed fee. Only relevant if a fixed fee applies."
                  >
                    <img alt="warning" src={infoIcon} />
                  </Tooltip>
                </InputLabel>
              </div>
              <div className="flex4">
                <Select
                  disabled={feeEarnerValidator.isDisabled || isFormDisabled}
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        ffFeeEarnerId: e.target.value,
                      },
                    });
                  }}
                  required={feeEarnerValidator.isRequired}
                  sx={{
                    maxWidth: 170,
                    minWidth: 170,
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.ffFeeEarnerId || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {filterInactiveStaff(
                    userList,
                    currentInvoiceDetails?.externalJSON?.ffFeeEarnerId
                  )?.map((item: any, index: number) => (
                    <option key={`staff-${index}`} value={item.staffId}>
                      {item.firstName} {item.lastName}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderBreakdown = () => {
    return (
      <>
        <DataGridPro
          columns={columns}
          columnHeaderHeight={30}
          columnVisibilityModel={{
            id: false,
          }}
          disableRowSelectionOnClick
          getRowId={(row: any) => row.id}
          hideFooter
          initialState={{}}
          rowHeight={26}
          rows={getDataRows()}
          showCellVerticalBorder
          showColumnVerticalBorder
          sx={{
            backgroundColor: "#fff",
            borderBottom: 0,
            borderLeft: 0,
            borderRight: 0,
            borderRadius: 0,
            ".MuiDataGrid-overlay": { color: "#0060bb" },
            ".MuiDataGrid-row:hover": {
              backgroundColor: "inherit",
            },
            ".MuiDataGrid-virtualScroller": { overflowX: "hidden" },
          }}
        />
      </>
    );
  };

  const renderTotals = () => {
    const totals = getTotals();

    return (
      <div className="displayFlex">
        <div className="flex1">
          <Typography fontWeight={600} marginTop={1} sx={{ color: "#0160aa" }}>
            Total Breakdown
          </Typography>
          <Typography color="grey" marginLeft={2}>
            <i>{totals.calculationLabel}</i>
          </Typography>
          <div style={{ display: "inline-flex", marginTop: 10 }}>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={currentInvoiceDetails?.totalAdjustments}
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">ADJUSTMENTS</Typography>
            </div>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={currentInvoiceDetails?.totalApplied}
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">TOTAL PAID</Typography>
            </div>
          </div>
        </div>
        <div>
          <div
            className="invoice-total-row"
            style={{
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <div style={{ width: 90 }}>
              <Typography style={{ fontWeight: 600 }}>TOTAL</Typography>
            </div>
            <div style={{ width: 92 }}>
              <NumberDisplay
                value={totals.totalTime}
                notCurrency
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
            <div style={{ width: 100 }}>
              <NumberDisplay
                value={totals.totalCost}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
            <div style={{ width: 100 }}>
              <NumberDisplay
                value={totals.totalVat}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
          </div>
          <div className="invoice-total-row">
            <div style={{ width: 90 }}>
              <Typography style={{ fontWeight: 600 }}>INC. TAX</Typography>
            </div>
            <div style={{ width: 192 }}>
              <NumberDisplay
                value={totals.totalCost + totals.totalVat}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
          </div>
          <div className="invoice-total-footer">
            <div style={{ width: 90 }}>
              <Typography style={{ color: "#db761d", fontWeight: 600 }}>
                BALANCE DUE
              </Typography>
            </div>
            <div style={{ width: 193 }}>
              <NumberDisplay
                value={calculateBalanceDue(
                  [
                    totals.totalCost,
                    totals.totalVat,
                    currentInvoiceDetails?.totalAdjustments || 0,
                  ],
                  [currentInvoiceDetails?.totalApplied || 0]
                )}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
                bold
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <TopBar
        leftComponents={
          <>
            <InvoiceDeleteButton
              invoiceDetails={currentInvoiceDetails}
              invoiceRef={invoiceRef.current}
            />
            <Tooltip placement="bottom-end" title="Reverse Transaction">
              <span>
                <IconButton
                  disabled={
                    !currentInvoiceDetails?.invoiceId ||
                    invoice.init.isReversedOrReversal ||
                    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                    toBeReversed
                  }
                  disableRipple
                  disableTouchRipple
                  onClick={() => {
                    setToBeReversed(true);
                  }}
                >
                  <ReverseIcon
                    disabled={
                      !currentInvoiceDetails?.invoiceId ||
                      invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                      toBeReversed
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </>
        }
        centerComponents={
          <ReversalInfo
            isReversal={
              invoice.init.isReversedOrReversal ||
              Boolean(currentInvoiceDetails?.reversedOrReversal)
            }
          />
        }
        helpUrl="https://community.leap.co.uk/s/article/Legal-Aid-Payment-on-Account"
        rightComponents={
          <>
            <Tooltip placement="bottom-end" title="Print">
              <IconButton
                disableRipple
                disableTouchRipple
                onClick={() => {
                  printReport(
                    () =>
                      setNotificationMessage(
                        "This transaction will be printed after saving."
                      ),
                    () =>
                      openReport(
                        currentInvoiceDetails?.invoiceId || "",
                        sdkApi
                      ),
                    () => dispatch(setPrintingEnabled(true)),
                    currentInvoiceDetails
                  );
                }}
                style={{ marginRight: 15 }}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <div className="invoice-main">
        <>
          <div className="invoice-criteria">{renderCriteria()}</div>
          <div className="poa-breakdown">{renderBreakdown()}</div>
          <div className="invoice-total">{renderTotals()}</div>
        </>
      </div>
      <Footer
        onCancel={() => {
          if (
            JSON.stringify(invoice.invoice) !==
            JSON.stringify(currentInvoiceDetails)
          ) {
            setShowUnsavedData(true);
          } else {
            handleClose();
          }
        }}
        onSave={() => {
          if (toBeReversed) {
            if (currentInvoiceDetails?.invoiceId) {
              dispatch(reverseInvoice(currentInvoiceDetails))
                .unwrap()
                .then(() => {
                  dispatch(
                    clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                  )
                    .then(() => close(sdkApi))
                    .catch((e) => console.error(e));
                });
            }
          } else {
            if (
              !!currentInvoiceDetails &&
              !validate(
                getRequiredFields(currentInvoiceDetails),
                [],
                (errors) => dispatch(setValidationErrors(errors))
              )?.length &&
              !saving
            ) {
              const totals = getTotals();
              const rows = getDataRows();
              const tempInvoice = {
                ...currentInvoiceDetails,
                externalJSON: {
                  ...currentInvoiceDetails?.externalJSON,
                  fixedFee: totals.totalCost,
                  fixedFeeVat: totals.totalVat,
                  poa: {
                    ...currentInvoiceDetails?.externalJSON?.poa,
                    profitCostTotalExVat:
                      rows.find((r) => r.description === "Profit Costs")
                        ?.amount || 0,
                    profitCostTotalVat:
                      rows.find((r) => r.description === "Profit Costs")?.tax ||
                      0,
                    fASTotalExVat:
                      rows.find((r) => r.description === "Advocacy (FAS only)")
                        ?.amount || 0,
                    fASTotalVat:
                      rows.find((r) => r.description === "Advocacy (FAS only)")
                        ?.tax || 0,
                    expertTotalExVat:
                      rows.find((r) => r.description === "Expert Fees")
                        ?.amount || 0,
                    expertTotalVat:
                      rows.find((r) => r.description === "Expert Fees")?.tax ||
                      0,
                    disbTotalExVat:
                      rows.find((r) => r.description === "Disbursements")
                        ?.amount || 0,
                    disbTotalVat:
                      rows.find((r) => r.description === "Disbursements")
                        ?.tax || 0,
                    items: selectedItems,
                  },
                },
              };
              if (totals.totalCost + totals.totalVat > 0) {
                dispatch(setLoader(true));
                if (currentInvoiceDetails.invoiceId) {
                  if (
                    currentInvoiceDetails.status !== invoice.invoice?.status
                  ) {
                    dispatch(updateInvoice(tempInvoice))
                      .unwrap()
                      .then(() => {
                        dispatch(
                          clearSemaphore(
                            currentInvoiceDetails.semaphoreId || ""
                          )
                        )
                          .then(() => close(sdkApi))
                          .catch((e) => console.error(e));
                      });
                  } else {
                    dispatch(
                      clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                    )
                      .then(() => close(sdkApi))
                      .catch((e) => console.error(e));
                  }
                } else {
                  dispatch(createInvoice(tempInvoice))
                    .unwrap()
                    .then((payload: IInvoiceDto) => {
                      dispatch(
                        clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                      ).then(() => {
                        if (invoice.precedentId) {
                          const staffId = !!decodedToken.current
                            ? decodedToken.current.staffId
                            : "";
                          dispatch(
                            createDocument({
                              precedentId: invoice.precedentId,
                              matterId: payload.matterId,
                              documentName: invoice.precedentList?.find(
                                (item) => item.guid === invoice.precedentId
                              )?.description,
                              staffInitials: userList?.find(
                                (s) => s.staffId === staffId
                              )?.initials,
                              parameters: {
                                invoiceId: payload.invoiceId,
                                staffId: staffId,
                              },
                            } as IDocBuilderModelDto)
                          ).then((action: any) => {
                            const docModel = action.payload as IDocModelDto;
                            openDocument(
                              {
                                documentId: docModel.documentId,
                                matterId: payload.matterId,
                              },
                              sdkApi
                            );
                            if (
                              invoice.isPrintingEnabled &&
                              docModel.documentId &&
                              payload.invoiceId
                            ) {
                              openReport(payload.invoiceId, sdkApi).then(() =>
                                close(sdkApi)
                              );
                            } else {
                              close(sdkApi);
                            }
                          });
                        } else if (
                          invoice.isPrintingEnabled &&
                          payload.invoiceId
                        ) {
                          openReport(payload.invoiceId, sdkApi).then(() =>
                            close(sdkApi)
                          );
                        } else close(sdkApi);
                      });
                    })
                    .catch((e) => console.error(e));
                }
              } else
                dispatch(
                  setValidationErrors([
                    "You cannot create an invoice with a value of £0.00",
                  ])
                );
            }
          }
        }}
      />
      <CustomDialog
        actions={
          <>
            <Button
              color="primary"
              disableRipple
              onClick={() => {
                setNotificationMessage(undefined);
              }}
              variant="contained"
            >
              OK
            </Button>
          </>
        }
        content={<Typography>{notificationMessage}</Typography>}
        isOpen={notificationMessage !== undefined}
        onClose={() => setNotificationMessage(undefined)}
        title="Notification"
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => {
          setShowUnsavedData(false);
        }}
        onYesCallback={() => handleClose()}
      />
      <InvoiceErrorDialog />
      <PoaDialog
        isOpen={showFeesDialog}
        onSelect={(items) => {
          setSelectedItems(items);
        }}
        items={invoice.invoice?.externalJSON?.poa?.items}
        onClose={() => {
          setShowFeesDialog(false);
        }}
        disabled={!!currentInvoiceDetails?.invoiceId}
      />
    </>
  );
};

export default InvoicePOADetails;
